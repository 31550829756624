<template>
  <div>
    <base-header class="pb-6" type="primary">
      <breadcrumb-header :items="breadcrumb"></breadcrumb-header>
    </base-header>

    <div class="container-fluid mt-6"
         :class="{'page-loading': apiStatus === 'loading'}"
    >
      <div class="alert alert-success alert-dismissible" v-if="postStatus === 'success'">
        Poprawnie zapisano dane
      </div>
      <div class="row">
        <div class="col-xl-6">
          <div class="card">
            <div class="card-header">
              <h3>{{ categoryInformation.company }}</h3>
            </div>
            <div class="card-body">
              {{ deliveryDetails }}
              <base-input
                type="text"
                v-model="categoryName"
                label="Nazwa kategorii"
                placeholder="Wprowadź nazwę kategorii"
              />
              <div class="row">
                <div class="col-6">
                  <div class="d-flex align-items-center justify-content-between mb-3">
                    <span>Aktywna</span>
                    <base-switch v-model="isCategoryActive" label="Aktywna" />
                  </div>
                </div>
                <div class="col-6">
                  <div class="d-flex align-items-center justify-content-between mb-3">
                    <span>Wewnętrzna</span>
                    <base-switch v-model="isCategoryInternal" label="Aktywna" />
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <div class="row">
                <div class="col-6">
                  <base-input label="Rabat dla kategorii" type="number" v-model="discount"/>
                </div>
                <div class="col-6">
                  <base-input label="Pozycja" type="number" v-model="position"/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-6">
          <div class="card">
            <div class="card-header"><h3>Opisy</h3></div>
            <div class="card-body">
              <base-input label="Krótki opis">
                <textarea
                  class="form-control mb-4"
                  placeholder="Wpisz krótki opis kategorii"
                  rows="4"
                  v-model="shortDescription"
                ></textarea>
              </base-input>
              <base-input label="Długi opis">
                <textarea
                  class="form-control mb-4"
                  placeholder="Wpisz długi opis kategorii"
                  rows="10"
                  v-model="longDescription"
                ></textarea>
              </base-input>
            </div>
          </div>
        </div>
      </div>
      <div class="text-right">
        <div class="btn btn-outline-primary">
          Anuluj
        </div>
        <div class="btn btn-primary" @click="handleSubmitForm">
          Zapisz zmiany
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BreadcrumbHeader from "@/views/Store/Layout/BreadcrumbHeader";
import { mapState, mapActions, mapMutations } from 'vuex';

export default {
  name: 'PartnerView',
  components: {
    BreadcrumbHeader,
  },
  data: () => ({
    isActive: false
  }),
  computed: {
    ...mapState('PartnerViewModule', ['categoryInformation', 'apiStatus', 'postStatus' ]),
    breadcrumb() {
      return [
        {title: 'Sklep', link: {name: 'store'}},
        {title: 'Kategorie', link: {name: 'product-category-list'}},
        {title: `Kategoria ID: ${this.$route.params.id}`}
      ];
    },
    deliveryDetails: {
      get() {
        return this.categoryInformation.delivery;
      },
      set(newVal) {
        console.log(newVal)
      }
    }
  },
  methods: {
    ...mapMutations('PartnerViewModule', ['updateCategoryField', 'resetCategoryInfo']),
    ...mapActions('PartnerViewModule', ['fetchCategoryInformation', 'submitForm']),
    async handleSubmitForm() {
      const handleCategoryId = this.$route?.params?.id ? `/${this.$route.params.id}` : '';
      await this.submitForm(handleCategoryId);
    }
  },
  async mounted() {
    if (this.$route.params.id) {
      await this.fetchCategoryInformation(this.$route.params.id);
    }
    console.log(this.categoryInformation)
  },
  beforeDestroy() {
    this.resetCategoryInfo();
  },
  watch: {
    $route(to, from) {
      if ( to !== from ) {
        this.resetCategoryInfo();
      }
    }
  }
};
</script>
<style>
.page-loading {
  opacity: 0.5;
}
</style>
